import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyDJTW0KPznGJ3Ne1s9u1jL9HIlqEI1G1VI',
  authDomain: 'auth.bodyhackers.cloud',
  databaseURL: 'https://cloud-rifd.firebaseio.com',
  projectId: 'cloud-rifd',
  storageBucket: 'cloud-rifd.appspot.com',
  messagingSenderId: '917612839116',
  appId: '1:917612839116:web:216543b24252b49ee0c269',
};
firebase.initializeApp(config);

export default firebase;
