<template>
  <div :class="$style.header">
    <a href="https://bodyhackers.cloud"><img src="@/assets/logo.svg" title="logo" :class="$style.logo" /></a>
  </div>
  <div :class="$style.container">
    <h1 :class="$style.center">Beta Tester</h1>
    <h2 :class="$style.center">Pre-registration</h2>
    <p>You are invited to join the Beta of <b>Bodyhacker's Cloud</b>!</p>
    <p>For more information, read the <u>Frequently Asked Questions</u> at the end of this page.</p>
    <div>
      <h3>How can I participate?</h3>
      <p>
        <template v-if="user==null">
          <i :class="$style.statusIcon + ' ' + $style.faTimes + ' fas fa-times'"></i>
        </template>
        <template v-else>
          <i :class="$style.statusIcon + ' ' + $style.faCheck + ' fas fa-check'"></i>
        </template>
        Click the "Sign in with Google" button to create an account. <br>
        <template v-if="user==null">
          <button :class="$style.button" @click="signInGoogle">
            <img src="@/assets/auth-google.svg" alt="G" @click="onGoogleSignIn">
            <span>Sign in with Google</span>
          </button>
        </template>
        <template v-else>
          <span :class="$style.loggedIn">
            <p><b>Welcome!<br>You are successfully registered! 🎉</b></p>
            <div>{{ user.displayName }} ({{ user.email }})</div>
            <div><small>(<a @click="onLogout">Logout</a>)</small></div>
          </span>
        </template>
      </p>
      <p>
        <template v-if="user==null">
          <i :class="$style.statusIcon + ' ' + $style.faTimes + ' fas fa-times'"></i>
        </template>
        <template v-else>
          <i :class="$style.statusIcon + ' ' + $style.faClock + ' fas fa-hourglass-half'"></i>
        </template>
           Once the beta starts,
        <template v-if="user==null">
             you will receive an email notification with more information.
        </template>
        <template v-else>
             you will receive an email notification to <u>{{ user.email }}</u> with more information.
        </template> Make sure it doesn't get lost in the spam folder.
      </p>
    </div>
    <h3>Frequently Asked Questions</h3>
    <div :class="$style.faqTitle">What is Bodyhacker's Cloud?</div>
    <div>Getting RFID, mangnet, ... implants is an experience! Therefore, it is important to keep these memories. Bodyhacker's Cloud is the place where you manage all your implants.</div>

    <div :class="$style.faqTitle">Why do I need to authenticate with Google?</div>
    <div>Since it is a cloud-based environment, authentication is necessary. Further authentication providers can be added later.</div>
    <div :class="$style.faqTitle">Why do I have to pre-register?</div>
    <div>You need to pre-register so that I can setup your account.
It is also important for me to know how many people are interested.</div>
    <div :class="$style.faqTitle">How long will the beta phase run? What happens after the beta phase?</div>
    <div>The Beta is a test run.
IF and HOW the project will continue after the Beta is uncertain!</div>
    </div>
</template>

<style module>
  .header{
    background: white;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-bottom: 1px solid #d2d2d2;
  }

  .center{
    text-align: center;
  }

  .logo{
    width: auto;
    height: 50px;
    margin: auto;
    display: block;
  }

  .faqTitle{
    font-weight: bold;
    padding-top: 20px;
  }

  .container{
    padding-top: 30px;
    padding-bottom: 30px;
    width: 500px;
    margin: auto;
  }

  .button{
    line-height: 3rem;
    width: 100%;
    color: #343a40;

    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
    background: white;
    border: 0;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 0em 0.5rem;
    margin-top: 10px;
    cursor: pointer;

    font-size: 0.9rem;
  }

  .button:not(:disabled):hover{
    /* box-shadow: inset rgb(0 0 0 / 20%) 1px 1px 5px 0px; */
    background: #f5f7f7;
  }

  .button > img,
  .button > svg{
    display: inline-block;
    height: 1.6rem;
    width: auto;
  }

  .loggedIn{
    background: white;
    border: 1px solid #b9b9b9;
    border-radius: 5px;
    margin-top: 0.3rem;
    display: block;
    padding: 20px;
  }

  .statusIcon{
    width: 30px;
    text-align: center;
    font-size: 1.3rem;
  }

  .faTimes{
    color: rgb(184, 32, 32);
  }

  .faCheck{
    color: rgb(32, 184, 100);
  }

  .faClock{
    color: rgb(62 62 62);
  }

  @media screen and (max-width: 500px) {
    .container{
      width: 90%;
    }
  }
</style>

<style>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>

<script>
import firebase from '@/Firebase';

export default {
  data() {
    return {
      user: null,
    };
  },
  components: {
  },
  methods: {
    signInGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then((result) => {
      }).catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        console.log(error.message);
      });
    },
    onLogout() {
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });
    },
  },
  created() {
    /* firebase.auth()
      .getRedirectResult()
      .then((result) => {
        console.log('logged in');
      }).catch((error) => {
        console.log(error.code);
        console.log(error.message);
        console.log(error.email);
        console.log(error.credential);
        console.log(error.code);
      }); */

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  },
};
</script>
